:root {
  // Colors
  --color-white: #ffffff;
  --color-black: #000000;

  --color-blue: #29a0da;
  --color-light-blue: #00adee;
  --color-red: #ff4b3c;
  --color-gray: #c4c4c4;

  --color-error: #ff0000;

  // Fonts
  --font-brand: "Futura New", sans-serif;

  --transition: 0.3s ease;
}
