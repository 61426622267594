.input {
  width: 100%;
  padding: 24px;
  background-color: var(--color-white);
  border-radius: 60px;
  -webkit-appearance: none;
  -webkit-box-shadow:rgba(0, 0, 0, 0.169);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.169);
  color: var(--color-blue);
  border: 1px solid transparent;
  font-size: 16px;
  line-height: 18px;

  @media (min-width: 768px) {
    padding: 20px;
    font-size: 18px;
    line-height: 22px;
  }

  &.error {
    border-color: var(--color-error);
    color: var(--color-error);
  }

  &::-webkit-input-placeholder {
    font-family: var(--font-brand);
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    text-underline-position: from-font;
    color: var(--color-gray);
    text-decoration-skip-ink: none;

    @media (min-width: 768px) {
      font-size: 18px;
      line-height: 22px;
    }
  }
}

.error-text {
  padding: 10px 0 0 20px;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
  color: var(--color-error);
}
