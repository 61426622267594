.registration {
  margin: 130px 0 0;
  min-height: calc(100vh - 130px);

  @media (min-width: 768px) {
    margin: 230px 0 0;
    min-height: calc(100vh - 230px);
  }
}

.block {
  display: flex;
  flex-direction: column;
  gap: 54px;
  padding: 36px 9px 44px;
  position: relative;
  border-radius: 26px;
  background: var(--color-white);
  max-width: 280px;
  margin: 0 auto;

  &::before {
    content: url('../../../assets/img/points-m.png');
    max-width: 185px;
    position: absolute;
    top: -36px;
    right: -38px;
    z-index: -1;

    @media (min-width: 768px) {
      content: url('../../../assets/img/points.png');
      max-width: 325px;
      top: -70px;
      right: -59px;
    }
  }

  &::after {
    content: url('../../../assets/img/points-m.png');
    max-width: 185px;
    position: absolute;
    bottom: -52px;
    left: -38px;
    z-index: -1;

    @media (min-width: 768px) {
      content: url('../../../assets/img/points.png');
      max-width: 325px;
      bottom: -63px;
      left: -68px;
    }
  }

  @media (min-width: 768px) {
    padding: 85px 40px 96px;
    max-width: 700px;
  }
}

.btn {
  max-width: 260px;
  align-self: center;
}

.title {
  font-family: var(--font-brand);
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  color: var(--color-blue);

  @media (min-width: 768px) {
    font-size: 22px;
    line-height: 27px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (min-width: 768px) {
    gap: 50px;
  }
}

.fields {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  gap: 20px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}



