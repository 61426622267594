@font-face {
  font-family: "Futura New";
  src:
    local("Futura New"),
    local("FuturaNew-Medium"),
    url("../assets/fonts/FuturaNew-Medium.otf") format("opentype"),
    url("../assets/fonts/FuturaNew-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Futura New";
  src:
    local("Futura New"),
    local("FuturaNew-Demi"),
    url("../assets/fonts/FuturaNew-Demi.otf") format("opentype"),
    url("../assets/fonts/FuturaNew-Demi.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Futura New";
  src:
    local("Futura New"),
    local("FuturaNew-Bold"),
    url("../assets/fonts/FuturaNew-Bold.otf") format("opentype"),
    url("../assets/fonts/FuturaNew-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
