.button {
  padding: 8px 16px;
  border-radius: 30px;
  background-color: var(--color-error);
  font-family: var(--font-brand);

  font-size: 8px;
  line-height: 10px;

  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  color: var(--color-white);
  transition: background-color var(--transition);

  @media (min-width: 768px) {
    padding: 20px 10px;

    font-size: 16px;
    line-height: 20px;
  }

  &:disabled {
    background-color: var(--color-gray);
    cursor: not-allowed;
  }

  &.block {
    width: 100%;
  }
}
