.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 11;
  background-color: var(--color-white);
  color: var(--color-blue);
  overflow: hidden;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px 0 0;

  @media (min-width: 768px) {
    padding: 0 40px 0 0;
  }
}

.logo {
  display: block;
  position: relative;
  z-index: 10;
  max-width: 103px;

  @media (min-width: 768px) {
    max-width: 225px;
  }
}

.title {
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  font-weight: 500;
  text-align: right;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  text-transform: uppercase;


  @media (min-width: 768px) {
    font-size: 25px;
    line-height: 32px;
  }
}
