* {
  outline: none;
  box-sizing: border-box;
}

html,
body {
  //height: 100%;
  //scrollbar-gutter: stable;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;

  color: var(--color-blue);
  background-color: #efefef;
  font-family: var(--font-brand), sans-serif;
  font-style: normal;
  font-weight: normal;
}

p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
  font-family: var(--font-brand), sans-serif;
}

input,
button,
textarea {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background-color: transparent;
  font-family: var(--font-brand), sans-serif;

  resize: none;
}

button {
  cursor: pointer;
}

table {
  border-collapse: collapse;
}

main {
  overflow: hidden;
}

.max-container {
  max-width: 1440px;
  margin: 0 auto;
}

.image-cover {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-contain {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.swiper-container {
  width: 100%;
}

.swiper-button-prev,
.swiper-button-next {
  display: none;
}

.custom-scrollbar {
  &::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--color-gray-400);
    border-radius: 6px;
  }

  &::-webkit-scrollbar-track-piece {
    margin: 24px;
  }
}

.swiper {
  overflow: hidden;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
}

.link {
  text-decoration: underline;
}

.container {
  max-width: 830px;
  margin: 0 auto;
  padding: 0 16px;
}
