.card {
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: var(--color-white);
  padding: 30px 12px 20px;
  max-width: 260px;
  width: 100%;
  border-radius: 18px;
  transition: background-color var(--transition);

  &:hover {
    background-color: var(--color-blue);

    .title {
      color: var(--color-white);
    }

    .button {
      background-color: var(--color-gray);
    }
  }

  @media (min-width: 768px) {
    max-width: 300px;
    padding: 56px 32px 40px;
    gap: 45px;
  }
}

.title {
  font-size: 11px;
  line-height: 13px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  color: var(--color-blue);
  transition: color var(--transition);


  @media (min-width: 768px) {
    font-size: 18px;
    line-height: 22px;
  }
}
