.modal {
  position: fixed;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  top: 29px;
  left: 0;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0 ;
  box-sizing: border-box;
  transition: opacity 0.4s;

  @media (min-width: 768px) {
    top: 107px;
  }
}

.overflow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  background-color: rgba(30, 30, 30, 0.49);

}

.container {
  position: relative;
  background-color: var(--color-white);
  padding: 80px 10px;
  height: fit-content;
  min-width: 280px;
  border-radius: 26px;

  @media (min-width: 768px) {
    padding: 80px 40px;
  }

  @media (min-width: 1024px) {
    padding: 125px 165px;
  }
}

.inner {

}

.title {
  font-family: var(--font-brand);
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  color: var(--color-blue);

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 30px;
  }
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: +1;
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}