.account {
  margin: 130px 0 0;
  min-height: calc(100vh - 130px);
  padding: 0 20px;

  @media (min-width: 768px) {
    margin: 230px 0 0;
    min-height: calc(100vh - 230px);
    padding: 0 40px;
  }
}

.cards {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  max-width: 940px;
  margin: 0 auto;

  // @supports (display: grid) {
  //   display: grid;
  //   grid-template-columns: repeat(auto-fit, minmax(135px, 1fr));
  //   justify-items: center;
  // }

  &::before {
    content: url('../../../assets/img/points-m.png');
    max-width: 185px;
    position: absolute;
    top: -36px;
    right: -38px;
    z-index: -1;

    @media (min-width: 768px) {
      content: url('../../../assets/img/points.png');
      max-width: 325px;
      top: -70px;
      right: -59px;
    }
  }

  &::after {
    content: url('../../../assets/img/points-m.png');
    max-width: 185px;
    position: absolute;
    bottom: -52px;
    left: -38px;
    z-index: -1;

    @media (min-width: 768px) {
      content: url('../../../assets/img/points.png');
      max-width: 325px;
      bottom: -63px;
      left: -68px;
    }
  }

  // @media (min-width: 768px) {
  //   @supports (display: grid) {
  //     grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  //   }
  // }

  // @media (min-width: 1024px) {
  //   @supports (display: grid) {
  //     grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  //   }
  // }
}